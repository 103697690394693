import { render, staticRenderFns } from "./reportTable.vue?vue&type=template&id=637a6b45&scoped=true&"
import script from "./reportTable.vue?vue&type=script&lang=js&"
export * from "./reportTable.vue?vue&type=script&lang=js&"
import style0 from "./reportTable.vue?vue&type=style&index=0&id=637a6b45&scoped=true&lang=css&"
import style1 from "./reportTable.vue?vue&type=style&index=1&id=637a6b45&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637a6b45",
  null
  
)

export default component.exports